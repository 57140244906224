import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleTileContainer } from './ExpertiseTileSimple.css';
import Text from '../Text/Text';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ExpertiseTileSimpleChild } from './ExpertiseTileSimple.css';
import HeadingContainer from '../PageSections/HeadingContainer/HeadingContainer';
import Fade from 'react-reveal/Fade';
import { Mobile, Desktop } from '../Media/Media';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'left',
    maxWidth: '100%',
    flexBasis: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '25%',
      flexBasis: '25%',
    },
  },
  container: {
    alignItems: 'self-start',
  },
}));

const ExpertiseTileSimple = ({ data }) => {
  const classes = useStyles();

  const [activeTitle, setActiveTitle] = useState({
    body: data[0].section_body.text,
    index: 1,
  });

  const handleActiveTile = (e) => {
    setActiveTitle({
      body: e.target.dataset.section,
      index: e.target.dataset.index,
    });
  };

  const headingProps = {
    title: activeTitle.index,
    body: activeTitle.body,
    className: 'ExpertisePage__HeadingContainer ExpertisePage__HighlightedTile',
    withRedPoint: false,
  };

  return (
    <SimpleTileContainer className={classes.root}>
      <Grid
        container
        className={`ExpertiseTile__Container ${classes.container}`}
        justify='flex-start'
      >
        {data &&
          data.map((section, index) => {
            return (
              <Grid
                item
                className={`${
                  activeTitle.index - 1 === index ? 'active' : ''
                } ExpertiseTile__Grid ${classes.grid}`}
                key={index}
              >
                <Fade delay={index * 150} bottom distance='30px'>
                  <ExpertiseTileSimpleChild className='ExpertiseTileSimple__Child'>
                    <Text customClassName='ExpertiseTileSimple__IndexTitle'>
                      {index + 1}
                    </Text>

                    <div
                      data-index={index + 1}
                      onClick={handleActiveTile}
                      data-section={section.section_body.text}
                      className='ExpertiseTile__ContentTitle'
                      dangerouslySetInnerHTML={{
                        __html: section.section_title.html,
                      }}
                    />
                    <Mobile>
                      <Text
                        customClassName='ExpertiseTileSimple__IndexBody'
                        type='primary'
                        dangerouslySetInnerHTML={{
                          __html: section.section_body.text,
                        }}
                      />
                    </Mobile>
                  </ExpertiseTileSimpleChild>
                </Fade>
              </Grid>
            );
          })}
      </Grid>
      <Desktop>
        <HeadingContainer noAnimation hideBorder {...headingProps} />
      </Desktop>
    </SimpleTileContainer>
  );
};

ExpertiseTileSimple.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ExpertiseTileSimple;
