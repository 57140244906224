
import styled from "@emotion/styled";
import { theme } from "../../styles";

export const GenericPageContainer = styled.div`

  .ArticleTemplate_Excerpt {
    font-size: 18px;
    margin-bottom: 50px;
    font-weight: 600;
    color: ${theme.colors.primaryblue};

    @media (min-width: ${theme.breakpoints.md}) {
      margin-top: 70px;
      font-size: 21px;
    }
  }
  
  .GenericPage__Content {
    @media (min-width: ${theme.breakpoints.md}) {
      width: 70%;
    }
  }
`;
