import styled from "@emotion/styled";
import { theme } from "../../styles";

export const ExpertiseTileContainer = styled.div`

`;

export const ExpertiseTileChild = styled.div`
  margin-bottom: 10px;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 60px;
  }

  .ExpertiseTile__IndexTitle {
    text-align: left;
    display: flex;
    width: 100%;
    font-weight: initial;
    padding-top: 20px;
    font-size: 45px;
    font-family: ${theme.fontFamily.tertiary};

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: ${theme.fonts.xxl_medium};
    }
  }

  .ExpertiseTile__ContentTitle {
    text-align: left;
    display: flex;
    font-weight: 600;
    padding-top: 35px;
    margin-bottom: 0px;
    margin-right: 20px;
    font-size: ${theme.fonts.l};
    color: ${theme.colors.primaryblue};
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    > * {
      margin: 0;
    }

    @media (max-width: ${theme.breakpoints.xs}) {
      padding-top: 20px;
      margin-right: 0px;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: 26px;
      hyphens: auto;
    }
  }

  .ExpertiseTile__ContentBody {
    padding-top: 35px;
    margin-bottom: 0px;
    margin-right: 20px;

    @media (max-width: ${theme.breakpoints.xs}) {
      padding-top: 20px;
      margin-right: 0px;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: 20px;
    }
  }
`;
