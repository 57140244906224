import React from 'react';
import PropTypes from 'prop-types';
import {
  TeamTileContainer,
  TeamContentContainer,
} from '../ProjectTiles/ProjectTiles.css';
import Img from 'gatsby-image';
import Title from '../Title/Title';
import LocalizedLink from '../LocalizedLink';
import Fade from 'react-reveal/Fade';

const TeamTile = (props) => {
  const { query, noLink = false } = props;
  const { section } = query;

  return noLink || !section?.uid ? (
    <div style={{ width: '100%' }}>
      <Tile {...props} />
    </div>
  ) : (
    <LocalizedLink style={{ width: '100%' }} to={`/${section?.uid}`}>
      <Tile {...props} />
    </LocalizedLink>
  );
};

TeamTile.propTypes = {
  query: PropTypes.object.isRequired,
  noLink: PropTypes.bool,
};

export default TeamTile;

const Tile = ({ query, customClassName, index }) => {
  const sectionData = query?.section?.document?.[0]?.data;

  return sectionData ? (
    <Fade bottom distance='30px' delay={index * 150}>
      <TeamTileContainer className={customClassName ? customClassName : ''}>
        {sectionData?.image?.localFile?.childImageSharp?.fluid ? (
          <Img
            className='ProjectTile__SinglePhoto'
            fluid={sectionData?.image?.localFile?.childImageSharp?.fluid}
          />
        ) : (
          <></>
        )}
        <TeamContentContainer className='TeamContent__Container'>
          {sectionData?.name ? (
            <Title customClassName='ProjectTile__Title'>
              {sectionData?.name?.text}
            </Title>
          ) : (
            ''
          )}
          {sectionData.qualification ? (
            <Title
              customClassName='ProjectTile__Qualification'
              dangerouslySetInnerHTML={{
                __html: sectionData.qualification.html,
              }}
            />
          ) : (
            ''
          )}
          {sectionData.position ? (
            <Title
              customClassName='ProjectTile__Position'
              dangerouslySetInnerHTML={{ __html: sectionData.position.html }}
            />
          ) : (
            ''
          )}
        </TeamContentContainer>
      </TeamTileContainer>
    </Fade>
  ) : (
    <></>
  );
};
