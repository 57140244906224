import styled from "@emotion/styled";
import { theme } from "../../styles";

export const PhotoTileContainer = styled.div`
  .ProjectTile__Title-container {
    padding: 0px !important;
    border-bottom: 0 !important;
    min-height: 100px;

    .ProjectTile__Title {
      padding: 0px !important;
    }
  }

  .ProjectTile_Border {
    border-bottom: none;
  }

  .ProjectTile__Country-text {
    min-height: 40px !important;
    padding-left: 0px !important;
    width: 100%;

    @media (min-width: ${theme.breakpoints.md}) {
      min-height: 60px !important;
      width: 100%;
    }
  }

  .ProjectTilesContainer__GridChild {
    @media (min-width: ${theme.breakpoints.md}) {
      margin-right: 20px;
    }

    &:nth-of-type(3n) {
      margin-right: 0px !important;
    }
  }
`;

export const ProjectTeamTilesChild = styled.div`
  width: 100%;

  @media (min-width: ${theme.breakpoints.xs}) {
    width: calc(50% - 7.5px);
  }

  @media (min-width: ${theme.breakpoints.md}) {
    width: calc(33.33% - 15px);
  }
`;
