import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getQuery } from '../../functions/getQuery';
import Img from 'gatsby-image';
import Title from '../Title/Title';
import SEO from '../SEO';
import {
  ArticleMainContainer,
  ArticleHeroContainer,
} from '../ArticleTemplate/ArticleTemplate.css';
import Transition from '../Transition/Transition';
import ExpertiseTile from '../OtherTiles/ExpertiseTile';
import HeadingContainer from '../PageSections/HeadingContainer/HeadingContainer';
import { ExpertiseTileStandardContainer } from '../PagesLayout/expertise.css';
import ExpertiseTileSimple from '../OtherTiles/ExpertiseTileSimple';
import TeamTiles from '../OtherTiles/TeamTiles';
import { GenericPageContainer } from '../PagesLayout/generic.css';
import Fade from 'react-reveal/Fade';
import Footer from '../Footer/Footer';

const GenericLayout = ({ data, pageContext, location }) => {
  let query;

  if (pageContext?.uid) {
    query = data.content.nodes.find((node) => node.uid === pageContext?.uid);
  } else {
    query = getQuery(['content', 'nodes', 0], data);
  }

  return (
    <GenericPageContainer>
      <SEO
        location={location}
        pathname={location.pathname}
        locale={pageContext?.locale}
      />
      <Transition>
        <ArticleMainContainer>
          <ArticleHeroContainer>
            {!query.data.hide_image &&
            query.data.image &&
            query.data.image.localFile ? (
              <Img
                fluid={query.data.image.localFile.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain' }}
                className='ArticleTemplateHero__Img'
              />
            ) : (
              ''
            )}
          </ArticleHeroContainer>
          <Fade bottom cascade distance='30px'>
            <div className='GenericPage__Content'>
              {query.data && query.data.title ? (
                <Title
                  type='h2_secondary'
                  customClassName='ArticleTemplate_Title'
                >
                  {query.data.title.text}
                </Title>
              ) : (
                ''
              )}
              {query.data?.excerpt?.html ? (
                <div
                  className='ArticleTemplate_Excerpt'
                  dangerouslySetInnerHTML={{ __html: query.data.excerpt.html }}
                />
              ) : (
                ''
              )}
              {query.data?.body ? (
                <div
                  className='ArticleTemplate_Body'
                  dangerouslySetInnerHTML={{ __html: query.data.body.html }}
                />
              ) : (
                ''
              )}
            </div>
          </Fade>
          {query.data.sections && query.data.sections.length > 0
            ? query.data.sections.map(
                (
                  {
                    section: {
                      document: [{ __typename, data: sectionData }],
                    },
                  },
                  index
                ) => {
                  switch (__typename) {
                    case 'PrismicOurValues':
                      return (
                        <Fragment key={__typename + '--' + index}>
                          {sectionData.title && sectionData.body ? (
                            <HeadingContainer
                              title={sectionData.title.text}
                              body={sectionData.body.text}
                            />
                          ) : (
                            ''
                          )}

                          {sectionData.sections ? (
                            <ExpertiseTile data={sectionData.sections} />
                          ) : (
                            ''
                          )}
                        </Fragment>
                      );
                    case 'PrismicTeam':
                      console.log(sectionData.sections);

                      return (
                        <Fragment key={__typename + '--' + index}>
                          {sectionData.title ? (
                            <HeadingContainer
                              title={sectionData.title.text}
                              body={
                                sectionData.body ? sectionData.body.text : ''
                              }
                              className='ExpertisePage_HeadingContainer ExpertisePage_HeadingContainer-PhotoSection'
                            />
                          ) : (
                            ''
                          )}
                          <Fade bottom distance='30px' delay={500}>
                            <ExpertiseTileStandardContainer>
                              <TeamTiles data={sectionData.sections} />
                            </ExpertiseTileStandardContainer>
                          </Fade>
                        </Fragment>
                      );
                    case 'PrismicInterventionPhilosophy':
                      return (
                        <Fragment key={`${__typename}--${index}`}>
                          {sectionData.title && sectionData.body ? (
                            <HeadingContainer
                              title={sectionData.title.text}
                              body={sectionData.body.text}
                              className='ExpertisePage_HeadingContainer'
                            />
                          ) : (
                            ''
                          )}
                          {sectionData.sections ? (
                            <Fade bottom distance='30px' delay={500}>
                              <ExpertiseTileSimple
                                data={sectionData.sections}
                              />
                            </Fade>
                          ) : (
                            ''
                          )}
                        </Fragment>
                      );
                    case 'PrismicContentBlock':
                      return (
                        <Fragment key={__typename + '--' + index}>
                          {sectionData.title ? (
                            <HeadingContainer
                              title={sectionData.title.text}
                              body={sectionData?.body?.html}
                              image={sectionData?.image?.url}
                              title_link={sectionData?.title_link?.url}
                              image_link={sectionData?.image_link?.url}
                              link_to_pdf={sectionData?.link_to_pdf?.url}
                              block_link={sectionData?.block_link?.url}
                              isHTML
                              className='ExpertisePage_HeadingContainer'
                            />
                          ) : (
                            ''
                          )}
                        </Fragment>
                      );
                    default:
                      return '';
                  }
                }
              )
            : ''}
          <Footer />
        </ArticleMainContainer>
      </Transition>
    </GenericPageContainer>
  );
};

GenericLayout.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default GenericLayout;
