import styled from "@emotion/styled";
import { theme } from "../../styles";

export const ExpertisePageContainer = styled.div`
  .ExpertisePage_HeadingContainer {
    margin-top: 80px;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-top: 100px;
    }
  }

  .HeadingContainerStyled {
    margin-top: 80px;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-top: 150px;
    }
  }
`;

export const ExpertiseTileStandardContainer = styled.div``;
