import styled from '@emotion/styled';
import { theme } from '../../styles';

export const SimpleTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  .active {
    @media (min-width: ${theme.breakpoints.md}) {
      color: ${theme.colors.primaryred};
    }
  }

  .ExpertisePage__HeadingContainer {
    margin-top: 80px;

    h3 {
      color: ${theme.colors.primaryblue};
      font-size: ${theme.fonts.l};
      font-family: ${theme.fontFamily.primary} !important;
      font-weight: 600;
      
      @media (min-width: ${theme.breakpoints.md}) {
        font-size: ${theme.fonts.xxl_medium};
      }
    }

    p {
      color: ${theme.colors.primaryblue};
      font-size: ${theme.fonts.md};

      @media (min-width: ${theme.breakpoints.md}) {
        font-size: ${theme.fonts.xl_small};
      }
    }
  }

  .ExpertisePage__HighlightedTile {
    margin-top: 30px;
    margin-bottom: 0;

    .HeadingContainer__TitleBody {
      width: 100%;
      color: ${theme.colors.primaryblue};

      @media (min-width: ${theme.breakpoints.md}) {
        width: 75%;
      }
    }

    .HeadingContainer__Title {
      width: 25%;
      margin-bottom: 40px;
      font-size: 45px;

      @media (min-width: ${theme.breakpoints.md}) {
        font-size: ${theme.fonts.xxl_medium};
      }
    }
  }
`;

export const ExpertiseTileSimpleChild = styled.div`
  padding-right: 40px;
  cursor: pointer;
  transition: color 300ms linear;

  @media (max-width: ${theme.breakpoints.s}) {
    padding-right: 10px;
  }

  @media (min-width: ${theme.breakpoints.s}) {
    padding-right: 91px;
  }

  @media (min-width: ${theme.breakpoints.md}) {
    padding-right: 75px;

    &:hover {
      color: ${theme.colors.primaryred};
    }
  }

  .ExpertiseTileSimple__IndexTitle {
    text-align: left;
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: ${theme.fonts.l};
    margin-bottom: 0px;

    @media (max-width: ${theme.breakpoints.md}) {
      font-size: 40px;
      margin-bottom: 25px;
    }
  }

  .ExpertiseTileSimple__IndexBody {
    color: ${theme.colors.primaryblue};
    margin-bottom: 40px;
  }

  .ExpertiseTile__ContentTitle {
    text-align: left;
    display: flex;
    font-weight: bold;
    font-size: ${theme.fonts.l};
    margin-bottom: 30px;
    flex-direction: column;
    justify-content: flex-start;
    line-height: 1.3;

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: 20px;
    }

    > * {
      margin: 0;
      pointer-events: none;
    }
  }

  .ExpertiseTile__Active {
    color: ${theme.colors.primaryred};
  }
`;

export const ActiveTileContainer = styled.div`
  display: flex;
`;
