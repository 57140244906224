import React from 'react';
import PropTypes from 'prop-types';
import { ExpertiseTileContainer } from './ExpertiseTile.css';
import Text from '../Text/Text';
import Title from '../Title/Title';
import Grid from '@material-ui/core/Grid';
import { theme as localTheme } from '../../styles';
import { makeStyles } from '@material-ui/core/styles';
import { ExpertiseTileChild } from './ExpertiseTile.css';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'left',
    maxWidth: '100%',
    flexBasis: '100%',
    '& .ExpertiseTile__ContentTitle': {
      color: localTheme.colors.primaryblue,
    },
    '& .ExpertiseTile__IndexTitle': {
      color: localTheme.colors.primaryblue,
    },
    [theme.breakpoints.up('400')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '25%',
      flexBasis: '25%',
    },
  },
  container: {
    alignItems: 'self-start',
  },
}));

const ExpertiseTile = ({ data }) => {
  const classes = useStyles();
  return (
    <ExpertiseTileContainer className={classes.root}>
      <Grid
        container
        spacing={3}
        className={`ExpertiseTile__Container ${classes.container}`}
        justify='flex-start'
      >
        {data &&
          data.map((section, index) => {
            return (
              <Grid
                item
                className={`ExpertiseTile__Grid ${classes.grid}`}
                key={index}
              >
                <Fade distance='30px' bottom delay={index * 150}>
                  <ExpertiseTileChild className={`ExpertiseTile__Child`}>
                    <Title
                      type='h3_secondary'
                      customClassName='ExpertiseTile__IndexTitle'
                    >
                      {index + 1}
                    </Title>
                    <Text
                      customClassName='ExpertiseTile__ContentTitle'
                      dangerouslySetInnerHTML={{
                        __html: section.section_title.html,
                      }}
                    />
                    <Text
                      customClassName='ExpertiseTile__ContentBody'
                      type='secondary'
                    >
                      {section.section_body.text}
                    </Text>
                  </ExpertiseTileChild>
                </Fade>
              </Grid>
            );
          })}
      </Grid>
    </ExpertiseTileContainer>
  );
};

ExpertiseTile.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ExpertiseTile;
