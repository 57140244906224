import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PhotoTileContainer, ProjectTeamTilesChild } from './TeamTiles.css';
import TeamTile from '../OtherTiles/TeamTile';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LocaleContext from '../../context/LocaleProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    alignItems: 'self-start',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const PhotoTile = ({ data }) => {
  const lang = useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];
  const classes = useStyles();

  return (
    <PhotoTileContainer className={classes.root}>
      <Grid container className={`${classes.container}`} justify='flex-start'>
        {data.map((tile, index) => {
          return tile?.section ? (
            <ProjectTeamTilesChild
              className={`${classes.grid} ProjectTilesContainer__GridChild ${
                index + (1 % 3) === 0 ? 'last-in-row' : ''
              }`}
              key={index}
            >
              <TeamTile
                index={index}
                key={`team  - ${index}`}
                noPlusSymbol
                customClassName='ExpertisePage__PhotoTile-noLink ProjectTileContainer'
                query={tile}
              />
            </ProjectTeamTilesChild>
          ) : (
            <></>
          );
        })}
      </Grid>
    </PhotoTileContainer>
  );
};

PhotoTile.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PhotoTile;
